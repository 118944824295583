import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Select,
  Spinner,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import Rowtable from "../row-table/row-table.component";
import styled from "styled-components";

const Tabledata = ({ data }) => {
  console.log("This is data : ", data);
  return (
    <TableContainer overflowX="auto" maxWidth="80vw">
      <Table
        boxShadow="xl"
        my="6"
        variant="striped"
        bg="white"
        borderRadius="10px"
      >
        <Thead>
          <Tr>
            <Th>Numéro ordre</Th>
            <Th>Nom complet</Th>
            <Th>Type Vehicule</Th>
            <Th>Date d'arrivé</Th>
            <Th>Date de retour</Th>
            <Th>N° de passagers</Th>
            <Th>départ de</Th>
            <Th>arrivée à</Th>
            <Th>prix</Th>
            <Th>Commentaires</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data?.length ? (
            data
              .sort((a, b) => b.id - a.id)
              .map(({ id, ...otherProps }) => (
                <Rowtable key={id} id={id} {...otherProps} />
              ))
          ) : (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default Tabledata;

const SpinnerWrapper = styled(Box)`
  width: 100%;
`;
