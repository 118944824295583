import React from "react";
import { Box, Text } from "@chakra-ui/react";
import Navigation from "../components/navigation/navigation.component";
import Header from "../components/header/header.component";
import { useHistory } from "react-router-dom";

const Layout = ({ children }) => {
  const history = useHistory();
  if (!localStorage.getItem("login")) {
    history.push("/login");
  }
  return (
    <Box width="100%" minHeight="100vh" display="flex" flexDir="column">
      <Header />
      <Box width="100%" flex="1" display="flex" flexDir="row">
        <Box flex="1" overflowX="auto" p="6">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
