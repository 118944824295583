import React from "react";
import {Box, Text, Flex} from "@chakra-ui/react";
import styled from "styled-components";

const CardInfo = ({background, children}) =>  {
    return (
        <Container >
            <RoundedStyle background={background}/>
            <Content>
            {children}
            </Content>
        </Container>

    )
}
export default CardInfo

const Container = styled(Box)`
background: #3E497A;
width: 30%;
padding: 16px;
position: relative;
display: flex;
flex-direction: column;
align-items:center;
color: #fff;

`;
const RoundedStyle = styled(Box)`
clip-path: circle(19.7% at 0 0);
background: ${props => props.background};
position: absolute;
right: 0;
top: 0;
left:0;
bottom:0;
cursor: pointer;
transition: all .5s;
&:hover{
    clip-path: circle(71.9% at 49% 51%);
}
`;
const Content = styled(Box)`
width: 100%;
height: 100%;
z-index: 5;
`;

