import React, { useEffect } from "react";
import {Select, useDisclosure} from "@chakra-ui/react";
import Modalpayment from "../modal-payment/modal-payment.component";
import { updateClientStatus } from "../../service/apis/clients/clients";

const SelectComponent = ({status, email, vehicule_type, id, amount}) => {
    console.log("This is the status : ", status);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [value, setValue] = React.useState(status);
   
    const change  = async (e) => {
        onOpen();
        setValue(e.target.value);
        
    }
    const selectRef = React.useRef(null);

        useEffect(() => {
        console.log(selectRef.current.value);
        if(selectRef.current.value.toLowerCase() === "confirm" ){
            selectRef.current.disabled = true;
        }else{
            selectRef.current.disabled = false;
        }
    }, [value])
     

    return (
        <>
        <Select ref={selectRef}  onChange={change} value={value}>
            <option value="accepter">Accepter</option>
            <option value="refuse">Refuser</option>
            <option value="publish">Publish</option>
            <option value="confirm">Confirm</option>
        </Select>
      <Modalpayment amount={amount} id={id} vehicule_type={vehicule_type} email={email} status={value} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        </>

    )
}

export default SelectComponent;