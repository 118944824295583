import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  Input,
  useToast,
} from "@chakra-ui/react";
import { updateClientStatus } from "../../service/apis/clients/clients";

const Modalpayment = ({
  id,
  isOpen,
  onClose,
  vehicule_type,
  status,
  email,
  amount,
}) => {
  console.log(id, isOpen, onClose, vehicule_type, status, email, amount);
  const [emailValue, setEmailValue] = React.useState(email);
  const [url, setUrl] = React.useState("");
  let toast = useToast();
  useEffect(() => {
    if (vehicule_type == "VAN") {
      setUrl("https://belgiumtransfer.be/asp-products/van/");
    } else if (vehicule_type == "Business") {
      setUrl("https://belgiumtransfer.be/asp-products/business/");
    } else if (vehicule_type == "SUV") {
      setUrl("https://belgiumtransfer.be/asp-products/suv/");
    } else if (vehicule_type == "Confort") {
      setUrl("https://belgiumtransfer.be/asp-products/confort/");
    } else if (vehicule_type == "Business") {
      setUrl("https://belgiumtransfer.be/asp-products/business/");
    } else if (vehicule_type == "Économie") {
      setUrl("https://belgiumtransfer.be/asp-products/economie/ ");
    }
  }, []);
  const getThePaymentUrl = async () => {
    try {
      const resp = await fetch(`https://belg.vercel.app/api/stripe`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          name: `${vehicule_type}${amount}`,
          priceProduct: Number(amount),
        }),
      });
      const respJson = await resp.json();
      return respJson.link.url;
    } catch (e) {
      console.log(e.message);
    }
  };

  const sendToEmail = async (data, url) => {
    try {
      if (emailValue && status && url) {
        const resp = await fetch("https://belg.vercel.app/api/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url: url,
            clientEmail: data.emailValue,
            accept: data.status,
          }),
        });
        const respJson = await resp.json();
        if (respJson.status) {
          toast({
            title: "Demande  envoyée.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Demande non envoyée.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Voulez vous{" "}
          {status == "accepter"
            ? "acceptez"
            : status == "confirm"
            ? "confirmez"
            : "refusez"}{" "}
          la commande ?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {status == "accepter" ? (
            <>
              <Text mb="6">Envoyer une demande de paiement : </Text>
              <Text>Montant : €{amount}</Text>
              <Box>
                <Text>Email clientele: </Text>
                <Input
                  value={emailValue}
                  onChange={setEmailValue}
                  type="email"
                />
              </Box>
            </>
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={async () => {
              console.log(status, emailValue);

              if (status != "confirm") {
                const url = await getThePaymentUrl();
                await sendToEmail({ emailValue, status }, url);
              } else {
                toast({
                  title: "Demande  envoyée",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
              }
              await updateClientStatus(id, status);

              onClose();
            }}
          >
            Envoyer
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Modalpayment;
