import React from "react";
import SelectComponent from "../select/select.component";
import { Tr, Td } from "@chakra-ui/react";

const Rowtable = ({
  first_name,
  last_name,
  vehicule_type,
  email,
  pickup_date,
  amount,
  id,
  status,
  pickeup_addr,
  drop_off_addr,
  num_passengers,
  commentaires,
  pickup_time,
}) => {
  return (
    <Tr>
      <Td>{id}</Td>
      <Td>{first_name + " " + last_name}</Td>
      <Td>{vehicule_type}</Td>
      <Td>{pickup_time}</Td>
      <Td>{pickup_date}</Td>

      <Td>{num_passengers}</Td>
      <Td minWidth="250px">{pickeup_addr}</Td>
      <Td minWidth="250px">{drop_off_addr}</Td>
      <Td>{amount}</Td>
      <Td>{commentaires}</Td>
      <Td minWidth="250px">
        <SelectComponent
          id={id}
          amount={amount}
          vehicule_type={vehicule_type}
          email={email}
          status={status}
        />
      </Td>
    </Tr>
  );
};

export default Rowtable;
