import {Base64} from 'js-base64';
export const getAllClients = async () => {
 
    try{
        const {REACT_APP_ENDPOINT} = process.env;
        const resp = await fetch(`https://belgiumtransfer.be/wp-json/wl/v1/posts`);
        const respJson = await resp.json();
        return respJson;
    }catch(e){
        console.log(e.message);
    }
}

export const updateClientStatus = async (id, status) => {

    try{
        const getToken = await fetch('https://belgiumtransfer.be/wp-json/jwt-auth/v1/token', {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
            },
            body:JSON.stringify({
                username: "admin",
                password: "4Qb4VWpHgIPzJYuUeefO"
            })
        })
        const responseJson = await getToken.json()
        if(responseJson.token){
            const resp = await fetch(`https://belgiumtransfer.be/wp-json/wp/v2/payment/`+Number(id) , {
            method: "PUT",
            headers: {
                'Content-type': 'application/json',
                'Authorization' : `Bearer ${responseJson.token}`  
            },
            body: JSON.stringify({
                status :String(status)
            })
        });
        const respJson = await resp.json();
        console.log(respJson)
        }
        
    }catch(e){
        console.log(e.message);
    }
}