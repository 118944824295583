import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import LoginForm from "../../components/login-form/login-form.component";

const LoginPage = () => {
  return (
    <Flex
      w="100%"
      height="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <LoginForm />
    </Flex>
  );
};

export default LoginPage;
