import React from "react";
import './App.css';
import LoginPage from "./pages/login/login.component";
import Homepage from "./pages/homepage/homepage.component";
import ClientPage from "./pages/client/client.component";
import { Route, Switch } from 'react-router-dom';

function App() {
  return (
    <div className="App">
          <Switch>
            <Route exact path="/" component={Homepage }/>
            <Route exact path="/clients" component={ClientPage} />
            <Route exact path="/login" component={LoginPage} />
        </Switch>
    </div>
  );
}

export default App;
