import React from "react";
import {Box} from "@chakra-ui/react";
import styled from "styled-components";

const Navitem = ({isMatched,children}) => {
    return (
        <Nav isMatched={isMatched}>
            {children}
        </Nav>
    )
}
export default Navitem;

const Nav = styled(Box)`
width: 100%;
margin: 16px 0;
border-radius: 10px;
cursor: pointer;
color: ${props => props.isMatched ? '#000' : '#fff'};
background: ${props => props.isMatched ? '#fff' : 'transparent'};
display: flex;
flex-direction: row;
align-items:center;
padding: 10px;
`;