import React from "react";
import {
  Box,
  Input,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Form, Formik, Field } from "formik";
import { useHistory } from "react-router-dom";

const LoginForm = () => {
  const history = useHistory();
  return (
    <Box boxShadow="xl" w="40%" bgColor="#fff" borderRadius="20px" p={4}>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={(values) => {
          console.log(values);
          if (
            values.username === process.env.REACT_APP_USER &&
            values.password === process.env.REACT_APP_PASSWORD
          ) {
            localStorage.setItem("login", JSON.stringify(true));
            history.push("/");
          }
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Text fontSize="2xl" mb="4" fontWeight="bold">
              Admin Dashboard
            </Text>
            <FormControl isInvalid={errors.username && touched.username}>
              <Field
                as={Input}
                name="username"
                id="username"
                placeholder="Username"
                type="text"
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password && touched.password}>
              <Field
                as={Input}
                name="password"
                id="password"
                placeholder="password"
                type="password"
                mt="4"
              />
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <Button
                colorScheme="teal"
                type="submit"
                variant="solid"
                w="100%"
                mt="4"
              >
                Login
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default LoginForm;
