import React from "react";
import {Box, Text, Input, InputGroup, InputLeftElement, Button} from "@chakra-ui/react";
import {BsSearch} from "react-icons/bs";
import Layout from "../../layout/layout.component";
import styled from "styled-components";
import Tabledata from "../../components/table/table.component";
const ClientPage = () => {
    return (
       <Layout>
           <Container>
               <Title  fontSize="2xl">Clients</Title>
               <SearchArea>
               <InputGroup>
    <InputLeftElement
      pointerEvents='none'
      children={<BsSearch color='gray.500' />}
    />
    <Input type='tel' placeholder='Chercher ' />
  </InputGroup>
  <Button ml="2" colorScheme="teal">Chercher</Button>
               </SearchArea>
               <Tabledata />
           </Container>
       </Layout>

    )
}

export default ClientPage;

const Container = styled(Box)`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
`;
const Title = styled(Text)`
width: 100%;
text-align:left;
font-weight: bold;
color: #2C3333;
`;
const SearchArea = styled(Box)`
width: 100%;
display: flex;
flex-direcion: row;
align-items:center;
margin-top: 16px;
`;