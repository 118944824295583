import React, {useState, useEffect} from "react";
import Layout from "../../layout/layout.component";
import Dashboard from "../../components/dashboard/dashboard.component";
import {getAllClients} from "../../service/apis/clients/clients";
const Homepage = () => {
     const [clients, setClients] = useState([]);
    useEffect(() => {
       const fetchClients = async () => {
           const data = await getAllClients();
           setClients(data);
    }
    fetchClients();
    }, [])
    return (
       <Layout>
           <Dashboard clients={clients} />
       </Layout>

    )
}

export default Homepage;