import React from "react";
import {Flex, Image, Text} from "@chakra-ui/react";
import {AiOutlineMenu} from "react-icons/ai";

const Header = () => {
    return (
        <Flex boxShadow="2xl" alignItems="center" w="100%" px="2" py="3" bgColor="teal">
            <AiOutlineMenu color="white"  fontSize="25px"/>
           <Text color="white" ml="2" fontWeight="bold" cursor="pointer">Gestion Clientelle</Text>
        </Flex>

    )
}

export default Header;