import React from "react";
import { Box, Text, Flex, Spinner } from "@chakra-ui/react";
import styled from "styled-components";
import CardInfo from "../card-info/card-info.component";
import Tabledata from "../table/table.component";
import DataTable from "react-data-table-component";
import SelectComponent from "../select/select.component";
const columns = [
  {
    name: "Commande ID",
    selector: (row) => row.id,
    width: "150px",
    sortable: true,
  },
  {
    name: "Client",
    selector: (row) => `${row.first_name + " " + row.last_name}`,
    minWidth: "220px",
  },
  {
    name: "Email",
    selector: (row) => `${row.email}`,
    minWidth: "280px",
  },
  {
    name: "Téléphone",
    selector: (row) => `${row.phone}`,
    minWidth: "130px",
  },
  {
    name: "Véhicule",
    selector: (row) => `${row.vehicule_type}`,
    minWidth: "130px",
  },
  {
    name: "Adresse/Date de départ",
    selector: (row) => `${row.pickeup_addr + ", " + row.pickup_time}`,
    minWidth: "330px",
  },
  {
    name: "Adresse/Date d'arriver",
    selector: (row) => `${row.drop_off_addr + ", " + row.pickup_date}`,
    minWidth: "330px",
  },
  {
    name: "N° passagers",
    selector: (row) => `${row.num_passengers}`,
  },
  {
    name: "Type",
    selector: (row) => `${row.trip_type}`,
  },
  {
    name: "Prix",
    selector: (row) => `${row.amount + " €"}`,
  },
  {
    name: "Action",
    width: "200px",
    selector: (row) => (
      <SelectComponent
        id={row.id}
        amount={row.amount}
        vehicule_type={row.vehicule_type}
        email={row.email}
        status={row.status}
      />
    ),
  },
];

const customStyles = {
  table: {
    style: {
      overflowX: "auto", // override the row height
    },
  },
};

const Dashboard = ({ clients }) => {
  return (
    <Box w="100%" flex="1">
      <NewCommande>
        <Title fontSize="2xl">Commandes</Title>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <DataTable
            noHeader
            customStyles={customStyles}
            columns={columns}
            data={clients}
            pagination
            highlightOnHover
          />
        </div>
      </NewCommande>
    </Box>
  );
};

export default Dashboard;

const Title = styled(Text)`
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: #2c3333;
`;

const Directory = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NewCommande = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
